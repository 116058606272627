import { useQuery } from '@tanstack/react-query';
import { fetchPositions, getMe } from '@/api/user/fetchers.ts';

export const useUserProfile = (isAuth: boolean, redirect?: () => void) => {
  return useQuery({
    queryKey: ['userProfile'],
    queryFn: () => getMe(redirect),
    enabled: isAuth,
  });
};

export const usePositionsQuery = (searchValue: string) => {
  return useQuery({
    queryKey: ['positions', searchValue],
    queryFn: () => fetchPositions(searchValue),
    enabled: !!searchValue.trim(),
    select: (data) => {
      return data?.map((position) => ({
        value: position.id,
        label: position.name,
      }));
    },
  });
};
