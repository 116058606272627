import { z } from 'zod';

const envSchema = z.object({
  VITE_SENTRY_DSN: z.string().url({
    message: 'Sentry DSN environment variable is not set or is invalid',
  }),
  VITE_SENTRY_ORG: z.string({
    message: 'Sentry organization environment variable is not set',
  }),
  VITE_SENTRY_PROJECT: z.string({
    message: 'Sentry project environment variable is not set',
  }),
  VITE_API_HOST: z
    .string()
    .url({ message: 'API host environment variable is not set or is invalid' }),
  VITE_APP_URL: z.string().optional(),
  VITE_GCAPTHA_SITE_KEY: z.string({
    message: 'Google reCAPTCHA site key environment variable is not set',
  }),
  VITE_SENTRY_ENV: z.string({
    message: 'Sentry environment is not set',
  }),
});

const env = envSchema.parse(import.meta.env);

export default env;
