import {
  Box,
  IconButton,
  Modal as MuiModal,
  ModalProps,
  styled,
  SxProps,
} from '@mui/material';
import { ReactNode } from 'react';
import CrossIcon from '@/assets/icons/cross_icon.svg?react';

const BoxWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '500px',
  width: '100%',
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '20px',
  minWidth: '340px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  maxHeight: 'calc(100% - 50px)',
  [theme.breakpoints.down('lg')]: {
    width: 'auto',
    padding: '16px',
  },
}));

type Props = ModalProps & {
  additionalButtons?: ReactNode;
  boxSx?: SxProps;
  children: ReactNode;
};

const Modal = ({ open, onClose, children, boxSx, ...props }: Props) => {
  return (
    <MuiModal open={open} onClose={onClose} {...props}>
      <BoxWrapper sx={boxSx}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={(e: any) => onClose?.(e, 'backdropClick')}>
            <CrossIcon />
          </IconButton>
        </Box>
        {children}
      </BoxWrapper>
    </MuiModal>
  );
};

export default Modal;
