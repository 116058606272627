import { ReactElement } from 'react';
import {UserType} from "@/types/user.ts";

export type BasicResponse<T> = {
  data: T;
};

export const BASE_URL = import.meta.env.VITE_API_HOST;

export type AccessCheckFn = (
  isAuth: boolean,
  isEmailVerified: boolean,
  isProfileCompleted?: boolean,
  role?: UserType['role'] | null
) => boolean;

interface RouteConfig {
  path: string;
  element: ReactElement;
  isProtected?: boolean;
  accessCheck?: AccessCheckFn;
  redirectTo?: string;
}

export type RoutesConfig = RouteConfig[];
