import { keyframes } from '@mui/material';

export const wordAppearAnimation = keyframes`
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const logoWrapperAnimation = keyframes`
  0% {
    opacity: 0.5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.2);
    width: 200px;
  }
  25%{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);    opacity: 1;
    width: 200px;
  }
  50% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);    opacity: 1;
    width: 200px

  }
  75% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);    opacity: 1;
    width: 200px

  }
  
  100% {
    top: 30px;
    left: 20px;
    transform: translate(0, 0) scale(1);   
    opacity: 1;
    width: 70px
  }
`;

export const wrapperAnimation = keyframes`
  0% {
    z-index: 15;
    display: flex;
    top: 0;
    opacity: 1
  }
  25%{
    z-index: 15;
    top: 0;
    opacity: 1
  }
  50% {
    z-index: 15;
    top: 0;
    opacity: 1
  }
  75% {
    z-index: 15;
    top: 0;
    opacity: 1
  }
  99%{
    z-index: -1;
    opacity: 0
  }
  100% {
    z-index: -1;
    opacity: 0
  }
`;
