import { z } from 'zod';
import validator from 'validator';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '@/components/forms/FormInput';
import { FieldsContainer } from '@/styled';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from '@/api/auth/fetchers.ts';
import { handleMutationError } from '@/utils/handleMutationError.ts';
import { toast } from 'sonner';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';

export const forgotPasswordSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .refine((value) => validator.isEmail(value), {
      message: 'Invalid email address',
    }),
});

export type ForgotPasswordField = z.infer<typeof forgotPasswordSchema>;

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const forgotPasswordMutation = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      toast.success(
        'If your email exists in the system we sent you a password reset link.'
      );
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  const methods = useForm<ForgotPasswordField>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = methods;

  const onSubmit = (data: ForgotPasswordField) => {
    forgotPasswordMutation.mutate(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <FormInput<ForgotPasswordField>
            name="email"
            control={control}
            label="Email"
            type="email"
            placeholder={'Enter your email'}
          />
          <Box
            sx={{
              marginTop: '100px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={
                !isDirty || !isValid || forgotPasswordMutation.isPending
              }
              type={'submit'}
            >
              Request reset link
            </Button>
            <Button
              onClick={() => {
                navigate(`/${PAGE_ROUTES.Login}`);
              }}
              variant="text"
              color="primary"
            >
              Login
            </Button>
          </Box>
        </FieldsContainer>
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
