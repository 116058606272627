import FormInput from '@/components/forms/FormInput';
import { useFormContext } from 'react-hook-form';
import { FieldsContainer } from '@/styled';
import { Box, Button } from '@mui/material';
import FormSelect from '@/components/forms/FormSelect';
import {
  AVAILABILITY_TYPE_SELECT_OPTIONS,
  PRICE_TYPE_SELECT_OPTIONS,
} from '@/constants';
import FormSkills from '@/components/forms/FormSkills';
import LocationAutoSuggest from '@/components/forms/LocationAutoSuggest';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';

const JobPostingFourthStep = () => {
  const { control } = useFormContext<JobCategoryFormInputs>();

  return (
    <FieldsContainer sx={{ gap: '25px' }}>
      <FormInput<JobCategoryFormInputs>
        name="title"
        control={control}
        label="Title"
        placeholder={'Enter job title'}
      />
      <Box
        sx={{
          display: 'flex',
          gap: { md: '90px', xs: '25px' },
          flexDirection: { md: 'row', xs: 'column' },
        }}
      >
        <Box sx={{ maxWidth: { md: '220px', xs: 'initial' }, width: '100%' }}>
          <FormSelect<JobCategoryFormInputs>
            name="type"
            control={control}
            label="Type"
            placeholder={'Select type'}
            options={PRICE_TYPE_SELECT_OPTIONS}
          />
        </Box>
        <Box sx={{ maxWidth: { md: '220px', xs: 'initial' }, width: '100%' }}>
          <FormInput<JobCategoryFormInputs>
            name="budget"
            control={control}
            label="Price"
            placeholder={'Enter price'}
            inputProps={{ min: 1, max: 10000000 }}
            type={'number'}
            startIcon={'$'}
            digitsOnly
          />
        </Box>
        <Box sx={{ maxWidth: { md: '220px', xs: 'initial' }, width: '100%' }}>
          <FormSelect<JobCategoryFormInputs>
            name="availability"
            control={control}
            label="Availability"
            placeholder={'Select availability'}
            options={AVAILABILITY_TYPE_SELECT_OPTIONS}
          />
        </Box>
      </Box>
      <Box sx={{ maxWidth: { md: '460px', xs: 'initial' }, width: '100%' }}>
        <LocationAutoSuggest
          placeholder={'Enter job location'}
          control={control}
        />
      </Box>
      <FormInput<JobCategoryFormInputs>
        name="description"
        control={control}
        label="Description"
        multiline
        rows={4}
        placeholder={'Enter job description'}
      />
      <Box sx={{ maxWidth: '550px', width: '100%' }}>
        <FormSkills<JobCategoryFormInputs> name={'skills'} control={control} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          gap: '40px',
          marginTop: '40px',
          width: '100%',
        }}
      >
        <Button
          sx={{ minWidth: '200px' }}
          variant={'contained'}
          type={'submit'}
        >
          Done
        </Button>
        <Button sx={{ minWidth: '200px' }} variant={'outlined'}>
          Cancel
        </Button>
      </Box>
    </FieldsContainer>
  );
};

export default JobPostingFourthStep;
