import useDebouncedSearch from '@/hooks/useDebouncedSearch.ts';
import FormAutoSuggest from '@/components/forms/FormAutoSuggest';
import { Control, useFormContext } from 'react-hook-form';
import { useLocationsQuery } from '@/api/auth/queries.ts';
import { useEffect } from 'react';
import { TalentFields } from '@/pages/OnboardingPage/components/TalentForm';

const LocationAutoSuggest = ({
  control,
  placeholder = 'Enter location',
}: {
  control: Control<any>;
  placeholder?: string;
}) => {
  const { setValue, watch } = useFormContext<TalentFields>();
  const locationSearch = watch('locationSearch');

  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(locationSearch);

  useEffect(() => {
    setValue('locationSearch', searchValue); // to have location search persistent
  }, [searchValue]);

  const { data: locations } = useLocationsQuery(searchValue);

  return (
    <FormAutoSuggest
      searchInputValue={locationSearch}
      name="locationId"
      control={control}
      label="Location"
      placeholder={placeholder}
      options={locations || []}
      onDebounce={handleDebounceSearchValue}
    />
  );
};

export default LocationAutoSuggest;
