import { Box, Radio, Typography } from '@mui/material';
import ArrowDownIcon from '@/assets/icons/arrow_down.svg?react';
import { useState } from 'react';
import { StyledCheckbox } from '@/styled';

type SelectableItem = {
  id: string;
  name?: string;
  value?: string;
};

type Props = {
  items: SelectableItem[];
  selectedValues: SelectableItem[];
  isSingleSelect?: boolean;
  onChange: (selected: SelectableItem[]) => void;
};

const SelectableList = ({
  items,
  selectedValues,
  isSingleSelect = false,
  onChange,
}: Props) => {
  const [showAll, setShowAll] = useState(false);

  const visibleItems = showAll ? items : items.slice(0, 5);

  const handleSelection = (item: SelectableItem, checked: boolean) => {
    let updatedValues;

    if (isSingleSelect) {
      updatedValues = checked ? [item] : [];
    } else {
      updatedValues = checked
        ? [...selectedValues, item]
        : selectedValues.filter((v) => v.id !== item.id);
    }

    onChange(updatedValues);
  };

  const handleClick = (item: SelectableItem) => {
    const isSelected = selectedValues.some((v) => v.id === item.id);
    handleSelection(item, !isSelected);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        marginTop: '20px',
      }}
    >
      {visibleItems.map((item) => {
        const isSelected = selectedValues.some((v) => v.id === item.id);
        return (
          <Box
            key={item.id}
            sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            {isSingleSelect ? (
              <Radio
                sx={{ padding: 0, height: '19px' }}
                checked={isSelected}
                onChange={(e) => handleSelection(item, e.target.checked)}
              />
            ) : (
              <StyledCheckbox
                sx={{ padding: 0 }}
                checked={isSelected}
                onChange={(e) => handleSelection(item, e.target.checked)}
              />
            )}
            <Typography
              variant="poppins16Regular"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClick(item)}
            >
              {item?.name || item?.value}
            </Typography>
          </Box>
        );
      })}
      {items?.length > 5 && (
        <Box
          onClick={() => setShowAll((prev) => !prev)}
          sx={{
            marginTop: '10px',
            cursor: 'pointer',
            color: 'purple1',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <Typography variant={'poppins16Regular'}>
            {showAll ? 'Show Less' : 'More'}
          </Typography>
          {!showAll && (
            <ArrowDownIcon style={{ width: '15px', height: '15px' }} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default SelectableList;
