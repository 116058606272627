import { Box, IconButton, styled, Typography } from '@mui/material';
import BackIcon from '@/assets/icons/back_icon.svg?react';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '40px',
  gap: '15px',
  [theme.breakpoints.down('sm')]: {},
}));

type Props = {
  title: string;
  onBackButton?: () => void;
};

const PageBackComponent = ({ title, onBackButton }: Props) => {
  const navigate = useNavigate();
  const handleBackButton = () => {
    if (onBackButton) {
      onBackButton();
    } else {
      if (window.history.length > 1) {
        navigate(-1);
      } else {
        navigate(`/${PAGE_ROUTES.Profile}`);
      }
    }
  };
  return (
    <Wrapper>
      <IconButton onClick={handleBackButton}>
        <BackIcon />
      </IconButton>
      <Typography variant={'poppins30Regular'}>{title}</Typography>
    </Wrapper>
  );
};

export default PageBackComponent;
