import { useJobPostingStepsStore } from '@/store/store/jobPostingStepStore.ts';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCategoriesQuery,
  useSubcategoriesQuery,
} from '@/api/jobPosting/queries.ts';
import JobPostingCategories from '@/components/common/JobPostingForm/JobPostingCategories.tsx';
import JobPostingSecondStep from '@/components/common/JobPostingForm/JobPostingSecondStep.tsx';
import JobPostingThirdStep from '@/components/common/JobPostingForm/JobPostingThirdStep.tsx';
import JobPostingFourthStep from '@/components/common/JobPostingForm/JobPostingFourthStep.tsx';
import { z } from 'zod';
import { useEffect } from 'react';
import { JobType } from '@/api/jobPosting/types.ts';
import { getLocationString } from '@/utils/getLocationString.ts';
import {AVAILABILITY_JOB_TYPE, JOB_TYPE} from "@/constants";

const SubcategoryFiltersType = z.object({
  filterId: z.string(),
  filterValueId: z.array(
    z.object({
      id: z.string(),
      value: z.string(),
    })
  ),
});

const jobCategorySchema = z.object({
  selectedCategoryId: z.string().min(1, 'You must select a category'),
  locationId: z.string().optional(),
  locationSearch: z.string().optional(),
  subCategoryId: z.string(),
  title: z.string().min(1),
  description: z.string().min(1),
  type: z.enum(JOB_TYPE),
  data: z.array(SubcategoryFiltersType).optional(),
  budget: z.coerce.number().min(0),
  skills: z.array(z.string()).optional(),
  availability: z.enum(AVAILABILITY_JOB_TYPE),
});

export type JobCategoryFormInputs = z.infer<typeof jobCategorySchema>;

type Props = {
  onSubmit: (data: JobCategoryFormInputs) => void;
  job?: JobType;
};

const JobPostingForm = ({ onSubmit, job }: Props) => {
  const currentStep = useJobPostingStepsStore((state) => state.currentStep);
  const methods = useForm<JobCategoryFormInputs>({
    resolver: zodResolver(jobCategorySchema),
    defaultValues: {
      selectedCategoryId: '',
      subCategoryId: '',
      type: 'FIXED',
      availability: 'CONTRACT',
      budget: 1
    },
  });

  const { watch, handleSubmit, reset } = methods;
  const selectedCategoryId = watch('selectedCategoryId');

  const { data: categories } = useCategoriesQuery();
  const { data: subcategories } = useSubcategoriesQuery(selectedCategoryId);

  useEffect(() => {
    if (job?.id) {
      reset({
        title: job?.title,
        description: job?.description,
        type: job?.type,
        budget: job?.budget,
        skills: job?.skills,
        availability: job?.availability,
        subCategoryId: job?.subCategory?.id,
        selectedCategoryId: job?.subCategory?.categoryId,
        locationId: job?.location?.id || '',
        locationSearch: getLocationString(job?.location),
        data: [
          ...(job.data || []).map((el) => ({
            filterId: el.id,
            name: el.name,
            filterValueId: [...(el.values || [])],
          })),
        ],
      });
    }
  }, [job?.id]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 1 && (
          <JobPostingCategories categories={categories || []} />
        )}
        {currentStep === 2 && (
          <JobPostingSecondStep subcategories={subcategories || []} />
        )}
        {currentStep === 3 && (
          <JobPostingThirdStep subcategories={subcategories || []} />
        )}
        {currentStep === 4 && <JobPostingFourthStep />}
      </form>
    </FormProvider>
  );
};

export default JobPostingForm;
