import { ProtectedRoute } from '@/components/common/ProtectedRoutes/ProtectedRoutes.tsx';
import { ReactNode } from 'react';
import { useAuthStore } from '@/store/authStore.ts';
import {AccessCheckFn} from "@/types/common.ts";

interface Props {
  path: string;
  children: ReactNode;
  isProtected?: boolean;
  accessCheck?: AccessCheckFn;
  redirectTo?: string;
}

export const ProtectedRouteWrapper = ({
  children,
  isProtected,
  redirectTo = 'signup',
  accessCheck,
}: Props) => {
  const { isAuth, isEmailVerified, isProfileCompleted, role } = useAuthStore();

  const isAllowed =
    !isProtected ||
    (accessCheck && accessCheck(isAuth, isEmailVerified, isProfileCompleted, role));

  return (
    <ProtectedRoute isAllowed={!!isAllowed} redirectTo={redirectTo}>
      {children}
    </ProtectedRoute>
  );
};
