import {
  Box,
  FormControl,
  FormLabel,
  InputProps,
  styled,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { StyledInput } from '@/styled';
import { useEffect, useState } from 'react';
import EyeIcon from '@/assets/icons/eye.svg?react';
import { StyledFormLabel } from '@/components/forms/FormLabel';
import { FirstRegistrationFields } from '@/pages/RegistrationPage/components/FirstRegistrationStep.tsx';

const BoxIcon = styled(Box)(() => ({
  position: 'absolute',
  right: '20px',
  top: '50%',
  transform: 'translateY(-100%)',
  display: 'flex',
  cursor: 'pointer',
}));

const FormConfirmPassword = ({ ...props }: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const {
    setValue,
    watch,
    clearErrors,
    setError: setFormError,
  } = useFormContext<FirstRegistrationFields>();
  const confirmPasswordValue = watch('confirmPassword');
  const passwordValue = watch('password');

  useEffect(() => {
    if (confirmPasswordValue && confirmPasswordValue !== passwordValue) {
      setError('Passwords do not match');
      setFormError('confirmPassword', {
        type: 'custom',
        message: 'Passwords do not match',
      });
    } else {
      setError('');
      clearErrors(['confirmPassword']);
    }
  }, [confirmPasswordValue, passwordValue]);

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <FormLabel component="legend" sx={{ width: '100%' }}>
        <StyledFormLabel htmlFor={'confirmPassword'}>
          Confirm password
        </StyledFormLabel>
      </FormLabel>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <StyledInput
          id={'confirmPassword'}
          error={!!error}
          onChange={(e) => setValue('confirmPassword', e.target.value)}
          value={confirmPasswordValue}
          fullWidth
          password={1}
          type={showPassword ? 'text' : 'password'}
          {...props}
        />
        <Typography variant={'error'} sx={{ opacity: !!error ? 1 : 0 }}>
          {error || 'helper text'}
        </Typography>
        <BoxIcon onClick={() => setShowPassword(!showPassword)}>
          {!showPassword ? <EyeIcon /> : <EyeIcon />}
        </BoxIcon>
      </Box>
    </FormControl>
  );
};

export default FormConfirmPassword;
