import { Box } from '@mui/material';

const ProfileInfo = () => {
  return (
    <Box sx={{ width: '100%', maxWidth: '420px' }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          minHeight: '500px',
          borderRadius: '15px',
        }}
      ></Box>
    </Box>
  );
};

export default ProfileInfo;
