import { useQuery } from '@tanstack/react-query';
import { fetchLocations, verifyEmailToken } from '@/api/auth/fetchers.ts';

export const useLocationsQuery = (searchValue: string) => {
  return useQuery({
    queryKey: ['locations', searchValue],
    queryFn: () => fetchLocations(searchValue),
    enabled: !!searchValue.trim(),
    select: (data) => {
      return data?.map((location: any) => ({
        value: location.locationId,
        label: `${location.city}, ${location.country}`,
      }));
    },
  });
};

export const useVerifyTokenQuery = (token: string | null) => {
  return useQuery({
    queryKey: ['verify-token', token],
    queryFn: () => verifyEmailToken(token!),
    enabled: !!token,
    retry: 0,
  });
};
