import { Box, Button, Typography } from '@mui/material';
import JobPostingCategoryItem from '@/components/common/JobPostingForm/JobPostingCategoryItem.tsx';
import BeautyIcon from '@/assets/icons/beauty_icon.svg?react';
import DancersIcon from '@/assets/icons/dancers_icon.svg?react';
import ArtistsIcon from '@/assets/icons/artists_icon.svg?react';
import HealthIcon from '@/assets/icons/health_&_wellness_icon.svg?react';
import SocialMediaIcon from '@/assets/icons/social_media_icon.svg?react';
import DesignIcon from '@/assets/icons/design_icon.svg?react';
import TechnologyIcon from '@/assets/icons/technology_icon.svg?react';
import ConstructionIcon from '@/assets/icons/construction_icon.svg?react';
import HomeOfficeIcon from '@/assets/icons/home_&_office_icon.svg?react';
import EducationIcon from '@/assets/icons/education_icon.svg?react';
import AutomotiveIcon from '@/assets/icons/automotive_icon.svg?react';
import FoodServicesIcon from '@/assets/icons/food_service_icon.svg?react';
import PetCareIcon from '@/assets/icons/pet_care_icon.svg?react';
import OtherIcon from '@/assets/icons/other_icon.svg?react';
import Grid from '@mui/material/Grid2';
import { Controller, useFormContext } from 'react-hook-form';
import { useJobPostingStepsStore } from '@/store/store/jobPostingStepStore.ts';
import { CategoryType } from '@/api/jobPosting/types.ts';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';

const nameToIconMap = {
  Designers: <DesignIcon />,
  Artists: <ArtistsIcon />,
  'Health & Wellness Professionals': <HealthIcon />,
  'Entertainment Industry': <DancersIcon />,
  Beauty: <BeautyIcon />,
  'Social Media-Related Jobs': <SocialMediaIcon />,
  Technology: <TechnologyIcon />,
  'Construction & Maintenance': <ConstructionIcon />,
  'Home and Office': <HomeOfficeIcon />,
  Education: <EducationIcon />,
  Automotive: <AutomotiveIcon />,
  'Food Services': <FoodServicesIcon />,
  'Pet Care': <PetCareIcon />,
};

const JobPostingCategories = ({
  categories,
}: {
  categories: CategoryType[];
}) => {
  const { control, watch } = useFormContext<JobCategoryFormInputs>();
  const selectedCategoryId = watch('selectedCategoryId');
  const setPrevNextStep = useJobPostingStepsStore(
    (state) => state.setPrevNextStep
  );
  return (
    <>
      <Typography
        sx={{ color: 'purple1', marginY: '30px', display: 'block' }}
        variant={'poppins30Semibold'}
      >
        Let’s find you a talent! In 4 easy steps
      </Typography>
      <Typography
        sx={{ marginTop: '30px', marginBottom: '20px', display: 'block' }}
        variant={'poppins20Regular'}
      >
        Select category
      </Typography>
      <Controller
        name="selectedCategoryId"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Grid container spacing={'20px'}>
            {categories?.map((category) => (
              <JobPostingCategoryItem
                key={category?.id}
                category={category}
                currentValue={value}
                icon={
                  nameToIconMap[
                    category.name as keyof typeof nameToIconMap
                  ] || <OtherIcon />
                }
                onChange={onChange}
              />
            ))}
          </Grid>
        )}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '40px',
        }}
        onClick={() => setPrevNextStep(1)}
      >
        <Button
          disabled={!selectedCategoryId}
          sx={{ width: '100%', maxWidth: { lg: '210px' } }}
          variant={'contained'}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default JobPostingCategories;
