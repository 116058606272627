import { useQuery } from '@tanstack/react-query';
import {
  fetchCategories,
  fetchJobPosting,
  fetchMyJobs,
  fetchSubcategories,
} from '@/api/jobPosting/fetchers.ts';

export const useCategoriesQuery = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: () => fetchCategories(),
    placeholderData: [],
  });
};
export const useSubcategoriesQuery = (categoryId: string) => {
  return useQuery({
    queryKey: ['subcategories', categoryId],
    queryFn: () => fetchSubcategories(categoryId),
    placeholderData: [],
    enabled: !!categoryId,
  });
};

export const useJobPostingQuery = (id?: string) => {
  return useQuery({
    queryKey: ['jobPosting', id],
    queryFn: () => fetchJobPosting(id || ''),
    enabled: !!id,
  });
};

export const useMyJobsQuery = () => {
  return useQuery({
    queryKey: ['myJobs'],
    queryFn: () => fetchMyJobs(),
  });
};
