import React from 'react';
import validator from 'validator';

export const parseDescription = (description: string): React.ReactNode[] => {
  const urlRegex =
    /(http|ftp|https):\/\/([\w_-]+(?:\.[\w_-]+)+)([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  const splitText = (
    text: string,
    regex: RegExp,
    createLink: (match: string, index: number) => React.ReactNode
  ) => {
    const parts: React.ReactNode[] = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
      const [matchedText] = match;
      const start = match.index;

      // Add text before the match
      if (start > lastIndex) {
        parts.push(text.slice(lastIndex, start));
      }

      // Add the matched link
      parts.push(createLink(matchedText, parts.length));
      lastIndex = start + matchedText.length;
    }

    // Add remaining text
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }

    return parts;
  };

  const processLinks = (text: React.ReactNode): React.ReactNode[] => {
    if (typeof text !== 'string') return [text];

    // Process URLs
    const withUrls = splitText(text, urlRegex, (url, index) =>
      validator.isURL(url, { require_protocol: true }) ? (
        <a
          key={`url-${index}`}
          href={url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {url}
        </a>
      ) : (
        url
      )
    );

    // Process Emails
    return withUrls.flatMap((part) =>
      typeof part === 'string'
        ? splitText(part, emailRegex, (email, index) =>
            validator.isEmail(email) ? (
              <a key={`email-${index}`} href={`mailto:${email}`}>
                {email}
              </a>
            ) : (
              email
            )
          )
        : [part]
    );
  };

  return processLinks(description);
};
