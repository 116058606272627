import { LayoutContainer } from '@/components/common/AuthLayout';
import AuthHeader from '@/components/common/AuthHeader';
import { Box, Button, styled, Typography } from '@mui/material';
import BigLogo from '@/assets/icons/big_logo.svg?react';
import ThinPlusIcon from '@/assets/icons/thin_plus_icon.svg?react';
import { StyledLandingSwitch } from '@/styled';
import LandingSearchInput from '@/components/forms/LandingSearchInput';
import { useState } from 'react';
import { useAuthStore } from '@/store/authStore.ts';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '@/constants';

const LogoWrapper = styled(Box)<{ searchfocused: number }>(
  ({ theme, searchfocused }) => ({
    transition: 'opacity 0.3s ease',
    opacity: searchfocused,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

const SwitchWrapper = styled(Box)<{ searchfocused: number }>(
  ({ searchfocused, theme }) => ({
    alignItems: 'center',
    transition: 'opacity 0.3s ease',
    opacity: searchfocused,
    display: 'flex',
    gap: '30px',
    marginTop: '40px',
    [theme.breakpoints.down('md')]: {
      gap: '12px',
    },
  })
);

const ButtonsWrapper = styled(Box)<{ searchfocused: number }>(
  ({ searchfocused, theme }) => ({
    transition: 'opacity 0.3s ease',
    opacity: searchfocused,
    display: 'flex',
    gap: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  })
);

const StyledLogo = styled(BigLogo)(() => ({
  width: '180px',
  height: '180px',
}));

const PositionWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '1200px',
  width: '100%',
  margin: '0 130px',
  position: 'relative',
  paddingBottom: '20px',
  [theme.breakpoints.down(1100)]: {
    margin: '0 40px',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '60px',
  fontFamily: 'Inter',
  fontWeight: 300,
  color: '#fff',
  lineHeight: '70px',
  [theme.breakpoints.down('md')]: {
    fontSize: '40px',
    lineHeight: '50px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '25px',
    lineHeight: '35px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    whiteSpace: 'nowrap',
    lineHeight: '35px',
  },
}));

const StyledButton = styled(Button)(() => ({
  borderRadius: 0,
  flex: 1,
  height: '76px',
  fontSize: '22px',
  fontWeight: 400,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {},
}));

const LandingSearchWrapper = styled(Box)(({ theme }) => ({
  margin: '40px 0',
  height: '110px',
  [theme.breakpoints.down('md')]: {
    height: '80px',
  },
}));

const LandingPage = () => {
  const navigate = useNavigate();
  const role = useAuthStore((state) => state.role);
  const isAuth = useAuthStore((state) => state.isAuth);
  const setRole = useAuthStore((state) => state.setRole);
  const [searchFocused, setSearchFocused] = useState(false);

  const onSearchFocused = () => setSearchFocused(true);

  const isHirer = role === 'Hirer';

  const switchRole = () => {
    setRole(isHirer ? 'Talent' : 'Hirer');
  };
  const handleExploreButton = () => {
    if (isAuth) {
      if (isHirer) {
        navigate(`/explore-offerings`);
      } else {
        navigate(`/explore-jobs`);
      }
    } else {
      navigate(`/${PAGE_ROUTES.Registration}`);
    }
  };

  const handlePostButton = () => {
    if (isAuth) {
      if (isHirer) {
        navigate(`/job-posting`);
      } else {
        navigate(`/offer-posting`);
      }
    } else {
      navigate(`/${PAGE_ROUTES.Registration}`);
    }
  };

  return (
    <LayoutContainer>
      <AuthHeader showLogo={searchFocused} showText={!searchFocused} />
      <ContentContainer sx={{ justifyContent: 'center', flexDirection: 'row' }}>
        <PositionWrapper>
          <LogoWrapper searchfocused={Number(!searchFocused)}>
            <StyledLogo />
          </LogoWrapper>
          <SwitchWrapper searchfocused={Number(!searchFocused)}>
            <StyledTypography>I’m a Hirer</StyledTypography>
            <StyledLandingSwitch checked={!isHirer} onChange={switchRole} />
            <StyledTypography>I’m a Talent</StyledTypography>
          </SwitchWrapper>
          <LandingSearchWrapper>
            <LandingSearchInput
              searchFocused={searchFocused}
              onSearchFocused={onSearchFocused}
            />
          </LandingSearchWrapper>
          <ButtonsWrapper searchfocused={Number(!searchFocused)}>
            <StyledButton
              onClick={handlePostButton}
              sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              variant={'contained'}
            >
              {isHirer ? 'Post a Job' : 'List Offering'} <ThinPlusIcon />
            </StyledButton>
            <StyledButton onClick={handleExploreButton} variant={'contained'}>
              {isHirer ? 'Explore Offerings' : 'Explore Jobs'}
            </StyledButton>
          </ButtonsWrapper>
        </PositionWrapper>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default LandingPage;
