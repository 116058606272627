import { toast } from 'sonner';
import { CodeErrorEnum } from '@/constants/errorCodes.ts';

export const handleMutationError = (error: Error) => {
  try {
    const errorMessage = JSON.parse(error?.message)?.message;
    const errorCode = JSON.parse(error?.message)?.code;
    switch (errorCode) {
      case CodeErrorEnum.INVALID_TOKEN: {
        toast.error(
          'This verification link has expired. Please request a new verification email to complete your registration.'
        );
        break;
      }
      default: {
        toast.error(errorMessage);
      }
    }
  } catch (e) {
    toast.error('An unknown error occurred.'); // to prevent parsing error
  }
};
