import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';
import Header from '@/components/common/Header';

export const LayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  maxHeight: '100vh',
  background: theme.palette.gradient,
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  flexDirection: 'row',
  maxWidth: '1440px',
  padding: '0 40px',
  [theme.breakpoints.down('md')]: {},
}));

type Props = {
  children: ReactNode;
};
const Layout = ({ children }: Props) => {
  return (
    <LayoutContainer>
      <Header />
      <Box
        sx={{
          overflowY: 'auto',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ContentContainer>
          <Box sx={{ width: '100%' }}>
            {children}
            <Box sx={{ display: 'block', height: '80px', width: '100%' }} />
          </Box>
        </ContentContainer>
      </Box>
    </LayoutContainer>
  );
};

export default Layout;
