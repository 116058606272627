import { useRef } from 'react';
import env from '@/env.ts';
import { z } from 'zod';
import validator from 'validator';
import { useMutation } from '@tanstack/react-query';
import { loginUser } from '@/api/auth/fetchers.ts';
import { useRegistrationStepsStore } from '@/pages/RegistrationPage/store/registrationStepsStore.ts';
import { UserType } from '@/types/user.ts';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldsContainer } from '@/styled';
import LoginAsField from '@/components/forms/LoginAsField';
import FormInput from '@/components/forms/FormInput';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Link } from '@mui/material';
import { useAuthStore } from '@/store/authStore.ts';
import { handleMutationError } from '@/utils/handleMutationError.ts';
import { PAGE_ROUTES } from '@/constants';

const siteKey = env.VITE_GCAPTHA_SITE_KEY;

export const loginSchema = z.object({
  role: z.enum(['Talent', 'Hirer']),
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .refine((value) => validator.isEmail(value), {
      message: 'Invalid email address',
    }),
  password: z.string().min(8, 'Password must be at least 8 characters long'),
  gRecaptchaResponse: z.string().min(1, { message: 'Required' }),
});

export type LoginFormFields = z.infer<typeof loginSchema>;

const LoginForm = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const setToken = useAuthStore((state) => state.setToken);
  const setVerificationEmail = useAuthStore(
    (state) => state.setVerificationEmail
  );

  const methods = useForm<LoginFormFields>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      role: 'Talent',
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = methods;

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: (data, variables) => {
      if (data?.accessToken) {
        setToken(data.accessToken);
        setVerificationEmail(variables?.email);
      }
    },
    onError: (error) => {
      handleMutationError(error);
      // Reset ReCAPTCHA on error
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setValue('gRecaptchaResponse', '');
      }
    },
  });

  const setSelectedRole = useRegistrationStepsStore(
    (state) => state.setSelectedRole
  );

  const onRoleChange = (role: UserType['role']) => {
    setValue('role', role);
    setSelectedRole(role);
  };

  const role = watch('role');

  const onSubmit = (data: LoginFormFields) => {
    loginMutation.mutate(data);
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <LoginAsField value={role} onChange={onRoleChange} />
          <FormInput<LoginFormFields>
            name="email"
            control={control}
            label="Email"
            type="email"
            placeholder={'Enter your email'}
          />
          <FormInput<LoginFormFields>
            name="password"
            control={control}
            label="Password"
            type="password"
            placeholder={'Enter your password'}
          />
          <Controller
            name={'gRecaptchaResponse'}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  onChange={(value) => onChange(value)}
                />
              );
            }}
          />
          <Link
            href={`/${PAGE_ROUTES.ForgotPassword}`}
            sx={{ cursor: 'pointer', color: 'purple1', marginTop: '16px' }}
          >
            Forgot password
          </Link>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '100px' }}
            disabled={!isDirty || !isValid || loginMutation.isPending}
            type={'submit'}
          >
            Login
          </Button>
        </FieldsContainer>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
