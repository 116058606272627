import { useState } from 'react';

const useDebouncedSearch = (defaultValue = '') => {
  const [searchValue, setSearchValue] = useState(defaultValue);
  const handleDebounceSearchValue = (value: string) => {
    setSearchValue(value);
  };

  return { searchValue, handleDebounceSearchValue };
};

export default useDebouncedSearch;
