import { Box, Button } from '@mui/material';
import JobPostingFilterBlock from '@/components/common/JobPostingForm/JobPostingFilterBlock.tsx';
import { useFormContext } from 'react-hook-form';
import { SubcategoryType } from '@/types/filters.ts';
import { useJobPostingStepsStore } from '@/store/store/jobPostingStepStore.ts';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';

const JobPostingThirdStep = ({
  subcategories,
}: {
  subcategories: SubcategoryType[];
}) => {
  const setPrevNextStep = useJobPostingStepsStore(
    (state) => state.setPrevNextStep
  );
  const { watch } = useFormContext<JobCategoryFormInputs>();
  const subCategoryId = watch('subCategoryId');
  const selectedCategory = subcategories?.find((el) => el.id === subCategoryId);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginTop: '40px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {selectedCategory?.filters?.map((filter) => {
        return (
          <JobPostingFilterBlock
            key={filter?.id}
            name={'data'}
            filter={filter}
          />
        );
      })}
      <Button
        onClick={() => {
          setPrevNextStep(1);
        }}
        variant={'contained'}
        sx={{ width: '100%', maxWidth: '460px' }}
      >
        Next
      </Button>
    </Box>
  );
};

export default JobPostingThirdStep;
