import AuthLayout from '@/components/common/AuthLayout';
import { FormCardBox } from '@/styled';
import { Typography } from '@mui/material';
import ForgotPasswordForm from '@/pages/ForgotPasswordPage/components/ForgotPasswordForm.tsx';

const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <FormCardBox sx={{ marginTop: '100px' }}>
        <Typography
          sx={{ textAlign: 'center', display: 'block', marginBottom: '40px' }}
          variant={'poppins30Semibold'}
        >
          Forgot Password
        </Typography>
        <ForgotPasswordForm />
      </FormCardBox>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
