export enum PAGE_ROUTES {
  Registration = 'signup',
  EmailVerification = 'verify-email',
  Onboarding = 'onboarding',
  Login = 'login',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  Landing = '',
  JobPosting = 'job-posting',
  Profile = 'profile',
  JobEdit = 'job-edit',
}

export const JOB_TYPE = ['YEARLY', 'WEEKLY', 'HOURLY', 'FIXED'] as const;
export const AVAILABILITY_TYPE = ['VIDEO', 'AUDIO', 'NONE'] as const;
export const AVAILABILITY_JOB_TYPE = [
  'CONTRACT',
  'FREELANCE',
  'FULL_TIME',
  'PART_TIME',
] as const;

export const AVAILABILITY_SELECT_OPTIONS: {
  value: (typeof AVAILABILITY_TYPE)[number];
  label: string;
}[] = [
  { value: 'VIDEO', label: 'Video Call' },
  { value: 'AUDIO', label: 'Audio Call' },
  { value: 'NONE', label: 'None' },
];

export const PRICE_TYPE_SELECT_OPTIONS: {
  value: (typeof JOB_TYPE)[number];
  label: string;
}[] = [
  { value: 'FIXED', label: 'Fixed' },
  { value: 'HOURLY', label: 'Hourly' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'YEARLY', label: 'Yearly' },
];

export const AVAILABILITY_TYPE_SELECT_OPTIONS: {
  value: (typeof AVAILABILITY_JOB_TYPE)[number];
  label: string;
}[] = [
  { value: 'CONTRACT', label: 'Contract' },
  { value: 'FREELANCE', label: 'Freelance' },
  { value: 'FULL_TIME', label: 'Full-time' },
  { value: 'PART_TIME', label: 'Part-time' },
];

export const COMPANY_SIZE_MARKS = [
  { value: 0, label: '0-50' },
  { value: 1, label: '50-100' },
  { value: 2, label: '100-500' },
  { value: 3, label: '500-1000' },
  { value: 4, label: '1000+' },
];
