import { Box, Button } from '@mui/material';
import JobPostingSubcategoriesBlock from '@/components/common/JobPostingForm/JobPostingSubcategoriesBlock.tsx';
import { useFormContext } from 'react-hook-form';
import { SubcategoryType } from '@/types/filters.ts';
import { useJobPostingStepsStore } from '@/store/store/jobPostingStepStore.ts';
import { JobCategoryFormInputs } from '@/components/common/JobPostingForm/index.tsx';

const JobPostingSecondStep = ({
  subcategories,
}: {
  subcategories: SubcategoryType[];
}) => {
  const setPrevNextStep = useJobPostingStepsStore(
    (state) => state.setPrevNextStep
  );
  const { watch } = useFormContext<JobCategoryFormInputs>();
  const subCategoryId = watch('subCategoryId');
  const selectedCategory = subcategories?.find((el) => el.id === subCategoryId);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginTop: '40px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <JobPostingSubcategoriesBlock
        name={'subCategoryId'}
        subcategories={subcategories}
        isSingleSelect={true}
      />
      <Button
        onClick={() => {
          if (!!selectedCategory?.filters?.length) {
            setPrevNextStep(1);
          } else {
            // SKIP ONE STEP IF NO FILTERS
            setPrevNextStep(2);
          }
        }}
        disabled={!subCategoryId}
        variant={'contained'}
        sx={{ width: '100%', maxWidth: '460px' }}
      >
        Next
      </Button>
    </Box>
  );
};

export default JobPostingSecondStep;
