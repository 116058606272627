import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Grid from '@mui/material/Grid2';

type Props = {
  category: { name: string; id: string };
  currentValue: string;
  onChange: (value: string) => void;
  icon: ReactNode;
};

const JobPostingCategoryItem = ({
  category,
  icon,
  currentValue,
  onChange,
}: Props) => {
  const isSelected = category.id === currentValue;
  return (
    <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2 }} key={category.id}>
      <Box
        onClick={() => onChange(category.id)}
        sx={{
          borderRadius: '10px',
          width: '100%',
          height: '145px',
          cursor: 'pointer',
          background: isSelected
            ? (theme) => theme.palette.purple1
            : (theme) => theme.palette.gradient7,

          display: 'flex',
          justifyContent: 'end',
          paddingBottom: '20px',
          paddingX: '10px',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '10px',
          color: !isSelected ? (theme) => theme.palette.purple1 : '#fff',
        }}
      >
        {icon}
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 500,
            color: !isSelected ? '#000' : '#fff',
          }}
          variant={'poppins18Regular'}
        >
          {category.name}
        </Typography>
      </Box>
    </Grid>
  );
};

export default JobPostingCategoryItem;
