import { Box, styled, Typography } from '@mui/material';
import AnimatedLogoIcon from '@/components/common/AnimatedLogoIcon';
import {
  logoWrapperAnimation,
  wordAppearAnimation,
  wrapperAnimation,
} from '@/components/common/MobileHeroAnimation/mobileAnimationKeyframes.ts';

const AnimatedWord = styled('span')<{ delay: number }>(({ delay }) => ({
  display: 'inline-block',
  animation: `${wordAppearAnimation} ${delay}s ease-out forwards`,
  opacity: 1,
  padding: '2px',
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.gradient1,
  position: 'fixed',
  left: 0,
  height: '100vh',
  width: '100%',
  zIndex: -1,
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${wrapperAnimation} 7s infinite  ease`,
  animationIterationCount: 1,
  top: 0,
  opacity: 0,
  display: 'flex',
}));
export const LogoWrapper = styled(Box)(() => ({
  animation: `${logoWrapperAnimation} 5s infinite ease`,
  animationIterationCount: 1,
  position: 'absolute',
  top: '30px',
  left: '20px',
  width: '70px',
}));
export const TitleWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: '25px',
  borderLeft: '1px solid #fff',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '320px',
  marginLeft: '15px',
  top: '30px',
  right: '20px',
  position: 'absolute',
  [theme.breakpoints.down(435)]: {
    right: '0',
  },
  [theme.breakpoints.down(415)]: {
    maxWidth: '240px',
    right: '20px',
  },
}));
export const TitleTypography = styled(Typography)(() => ({
  // animation: `${logoWrapperAnimation} 3s infinite  ease`,
  animationIterationCount: 1,
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 400,
  color: '#fff',
  display: 'flex',
  flexWrap: 'wrap',
}));

const MobileHeroAnimation = () => {
  const text = 'Talenver, where talent meets opportunity.';
  const words = text.split(' ');
  return (
    <Wrapper>
      <LogoWrapper>
        <AnimatedLogoIcon style={{ width: '100%' }} />
      </LogoWrapper>
      <TitleWrapper>
        <TitleTypography>
          {words.map((word, index) => (
            <AnimatedWord key={index} delay={index}>
              {word}
            </AnimatedWord>
          ))}
        </TitleTypography>
      </TitleWrapper>
    </Wrapper>
  );
};

export default MobileHeroAnimation;
