import Layout from '@/components/common/Layout';
import HirerProfile from '@/pages/ProfilePage/components/hirer/HirerProfile.tsx';
import { useAuthStore } from '@/store/authStore.ts';
import TalentProfile from '@/pages/ProfilePage/components/talent/TalentProfile.tsx';

const ProfilePage = () => {
  const role = useAuthStore((state) => state.role);
  return (
    <Layout>
      {role === 'Hirer' && <HirerProfile />}
      {role === 'Talent' && <TalentProfile />}
    </Layout>
  );
};

export default ProfilePage;
