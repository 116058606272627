import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { StyledInput } from '@/styled';
import { ReactNode } from 'react';
import { StyledFormLabel } from '@/components/forms/FormLabel';

type FormSelectProps<TSchema extends FieldValues> = SelectProps & {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: ReactNode;
  options: Array<{ value: string | number; label: string }>;
  placeholder?: string;
};

const FormSelect = <TSchema extends FieldValues>({
  name,
  label,
  control,
  options,
  placeholder,
  ...props
}: FormSelectProps<TSchema>) => {
  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <StyledFormLabel htmlFor={name}>{label}</StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Select
              id={name}
              error={!!error}
              onChange={onChange}
              value={value || ''}
              input={<StyledInput />}
              fullWidth
              displayEmpty
              {...props}
            >
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant="error"
              sx={{ opacity: !!error?.message ? 1 : 0 }}
            >
              {error?.message || 'helper text'}
            </Typography>
          </Box>
        )}
      />
    </FormControl>
  );
};

export default FormSelect;
