import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormInput from '@/components/forms/FormInput';
import { FieldsContainer } from '@/styled';
import { useMutation } from '@tanstack/react-query';
import { resetPassword } from '@/api/auth/fetchers.ts';
import { handleMutationError } from '@/utils/handleMutationError.ts';
import { toast } from 'sonner';
import { Box, Button, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import FormConfirmPassword from '@/components/forms/FormConfirmPassword';
import { PAGE_ROUTES } from '@/constants';
import { CodeErrorEnum } from '@/constants/errorCodes.ts';

export const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .refine((value) => /[A-Z]/.test(value), {
        message: 'Password must contain at least one uppercase letter',
      })
      .refine((value) => /\d/.test(value), {
        message: 'Password must contain at least one number',
      })
      .refine((value) => /[!@#$%^&*(),.?":{}|<>]/.test(value), {
        message: 'Password must contain at least one special character',
      }),
    confirmPassword: z.string().min(1, { message: 'Required' }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
  });

export type ResetPasswordFields = z.infer<typeof resetPasswordSchema>;

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query?.get('t');

  const resetPasswordMutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      toast.success(
        'Password was reset successfully you may now proceed to login'
      );
      navigate(`/${PAGE_ROUTES.Login}`);
    },
    onError: (error) => {
      const errorCode = JSON.parse(error?.message)?.code;
      if (errorCode === CodeErrorEnum.INVALID_OR_EXPIRED_TOKEN) {
        toast.error(
          <div>
            Password Reset Link Expired. <br />
            Request a{' '}
            <Link href={`/${PAGE_ROUTES.ForgotPassword}`}>
              new password reset link
            </Link>{' '}
            to continue.
          </div>
        );
      } else {
        handleMutationError(error);
      }
    },
  });
  const methods = useForm<ResetPasswordFields>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
  } = methods;

  const confirmPassword = watch('confirmPassword');

  const onSubmit = (data: ResetPasswordFields) => {
    const { password } = data;
    if (token) {
      resetPasswordMutation.mutate({ newPassword: password, token });
    }
  };

  const isSubmitDisabled =
    !isDirty ||
    !!Object.keys(errors)?.length ||
    !confirmPassword ||
    !token ||
    resetPasswordMutation.isPending;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldsContainer>
          <FormInput<ResetPasswordFields>
            name="password"
            control={control}
            label="Password"
            type="password"
            placeholder={'Enter your password'}
          />
          <FormConfirmPassword placeholder={'Enter your confirm password'} />
          <Box
            sx={{
              marginTop: '100px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitDisabled}
              type={'submit'}
            >
              Reset Password
            </Button>
          </Box>
        </FieldsContainer>
      </form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
