import { FormLabel, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  horizontal?: boolean;
  sx?: SxProps;
  htmlFor: string;
  onClick?: () => void;
};

export const StyledFormLabel = ({
  onClick,
  children,
  horizontal,
  sx,
  htmlFor,
}: Props) => {
  return (
    <FormLabel onClick={onClick} htmlFor={htmlFor} component="label">
      <Typography
        variant="poppins16Medium"
        sx={{
          ...(horizontal && { marginLeft: '20px' }),
          ...(!horizontal && { marginBottom: '10px' }),
          display: 'block',
          color: '#051320',
          ...sx,
        }}
      >
        {children}
      </Typography>
    </FormLabel>
  );
};

export default FormLabel;
