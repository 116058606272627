import Modal from '@/components/common/Modal';
import { Box, Button, Typography } from '@mui/material';

type Props = {
  open: boolean;
  title: string;
  subtitle: string;
  cancelButtonText: string;
  cancelButtonClick: () => void;
  submitButtonClick: () => void;
  submitButtonText: string;
};

const ConfirmationModal = ({
  open,
  cancelButtonClick,
  submitButtonClick,
  title,
  subtitle,
  cancelButtonText,
  submitButtonText,
}: Props) => {
  return (
    <Modal open={open} onClose={cancelButtonClick}>
      <>
        <Typography
          sx={{ textAlign: 'center', marginTop: '20px' }}
          variant={'poppins24Medium'}
        >
          {title}
        </Typography>
        <Typography
          sx={{ textAlign: 'center', marginTop: '20px' }}
          variant={'poppins16Regular'}
        >
          {subtitle}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '15px',
            width: '100%',
            marginTop: '40px',
          }}
        >
          <Button
            sx={{ maxWidth: '200px', width: '100%' }}
            onClick={cancelButtonClick}
            variant={'outlined'}
          >
            {cancelButtonText}
          </Button>
          <Button
            sx={{ maxWidth: '200px', width: '100%' }}
            onClick={submitButtonClick}
            variant={'contained'}
          >
            {submitButtonText}
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
