import { Box } from '@mui/material';
import PageBackComponent from '@/components/common/PageBackComponent';

const TalentProfile = () => {
  return (
    <Box>
      <PageBackComponent title={'Talent Profile'} />
    </Box>
  );
};

export default TalentProfile;
