import { Box, styled, Typography } from '@mui/material';
import AnimatedLogoIcon from '@/components/common/AnimatedLogoIcon';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '30px 20px',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 20px',
  },
}));

export const TextBox = styled(Box)(({ theme }) => ({
  paddingLeft: '25px',
  borderLeft: '1px solid #fff',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '320px',
  marginLeft: '15px',
  [theme.breakpoints.down(415)]: {
    maxWidth: '240px',
    right: '60px',
  },
}));

type Props = {
  showLogo?: boolean;
  showText?: boolean;
};

const AuthHeader = ({ showLogo = true, showText = true }: Props) => {
  return (
    <StyledHeader>
      <AnimatedLogoIcon
        style={{ minWidth: '70px', height: '70px', opacity: Number(showLogo) }}
      />
      <TextBox sx={{ opacity: Number(showText) }}>
        <Typography
          sx={{
            color: '#fff',
            fontSize: { xs: '20px', sm: '20px' },
          }}
          variant={'inter30Regular'}
        >
          Talenver, where talent meets opportunity.
        </Typography>
      </TextBox>
    </StyledHeader>
  );
};

export default AuthHeader;
