import { Box, Typography } from '@mui/material';
import JobPostedItem from '@/pages/ProfilePage/components/hirer/JobPostedItem.tsx';
import { JobType } from '@/api/jobPosting/types.ts';
import { useUserProfile } from '@/api/user/queries.ts';
import { useAuthStore } from '@/store/authStore.ts';

type Props = {
  jobs: JobType[];
};

const JobPostedList = ({ jobs }: Props) => {
  const isAuth = useAuthStore((state) => state.isAuth);
  const { data: profile } = useUserProfile(isAuth);
  const author = {
    src: profile?.photo?.url || '',
    name: `${profile?.firstName} ${profile?.lastName}`,
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant={'poppins16Semibold'}>My Posted Jobs</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '25px',
          marginTop: '20px',
        }}
      >
        {jobs?.map((el) => (
          <JobPostedItem key={el.id} job={el} author={author} />
        ))}
      </Box>
    </Box>
  );
};

export default JobPostedList;
