import { ReactNode, useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import ArrowDownIcon from '@/assets/icons/arrow_down.svg?react';

type Props = {
  title: string;
  children: ReactNode;
};

const CollapsibleBlock = ({ title, children }: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Box
      sx={{
        maxWidth: '640px',
        width: '100%',
        padding: '20px',
        borderRadius: '10px',
        background: (theme) => theme.palette.gradient7,
      }}
    >
      <Box
        onClick={() => setIsExpanded((prev) => !prev)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <Typography variant="poppins16Medium">{title}</Typography>
        <IconButton
          sx={{
            transform: !isExpanded ? 'rotate(-90deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
          size="small"
        >
          <ArrowDownIcon />
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>{children}</Collapse>
    </Box>
  );
};

export default CollapsibleBlock;
