import { Box, styled } from '@mui/material';
import HeaderLogo from '@/assets/icons/header_logo.svg?react';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '25px 70px',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: '#fff',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 20px',
  },
}));

const Header = () => {
  return (
    <StyledHeader>
      <HeaderLogo />
    </StyledHeader>
  );
};

export default Header;
