import PageBackComponent from '@/components/common/PageBackComponent';
import JobPostingStepper from '@/components/common/JobPostingForm/JobPostingStepper.tsx';
import JobPostingForm, {
  JobCategoryFormInputs,
} from '@/components/common/JobPostingForm';
import Layout from '@/components/common/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useJobPostingStepsStore } from '@/store/store/jobPostingStepStore.ts';
import { useJobPostingQuery } from '@/api/jobPosting/queries.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { editJobPosting } from '@/api/jobPosting/fetchers.ts';
import { handleMutationError } from '@/utils/handleMutationError.ts';
import { toast } from 'sonner';
import { PAGE_ROUTES } from '@/constants';

const JobEditPage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const currentStep = useJobPostingStepsStore((state) => state.currentStep);
  const setPrevNextStep = useJobPostingStepsStore(
    (state) => state.setPrevNextStep
  );
  const setCurrentStep = useJobPostingStepsStore(
    (state) => state.setCurrentStep
  );

  const editJobPostingMutation = useMutation({
    mutationFn: editJobPosting,
    onSuccess: (data) => {
      toast.success('Job updated');
      queryClient.invalidateQueries({ queryKey: ['jobPosting', data?.id] });
      navigate(`/${PAGE_ROUTES.Profile}`);
      setCurrentStep(1);
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  const onSubmit = (data: JobCategoryFormInputs) => {
    const { selectedCategoryId, locationSearch, ...filteredData } = data;
    const filters = filteredData?.data;
    const transformedData = {
      ...filteredData,
      data: [
        ...(filters || []).map((el) => ({
          ...el,
          filterValueId: (el.filterValueId || []).map((el) => el.id),
        })),
      ],
    };
    if (id) {
      editJobPostingMutation.mutate({ id, body: transformedData });
    }
  };

  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate(-1);
    } else {
      setPrevNextStep(-1);
    }
  };

  const { data: job } = useJobPostingQuery(id);

  return (
    <Layout>
      <PageBackComponent onBackButton={handleBackButton} title={'Edit Job'} />
      <JobPostingStepper />
      <JobPostingForm job={job} onSubmit={onSubmit} />
    </Layout>
  );
};

export default JobEditPage;
