import { Box, styled } from '@mui/material';
import PageBackComponent from '@/components/common/PageBackComponent';
import ProfileInfo from '@/pages/ProfilePage/components/ProfileInfo.tsx';
import JobPostedList from '@/pages/ProfilePage/components/hirer/JobPostedList.tsx';
import { useMyJobsQuery } from '@/api/jobPosting/queries.ts';

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '40px',
  gap: '20px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const HirerProfile = () => {
  const { data: jobs } = useMyJobsQuery();
  return (
    <Box>
      <PageBackComponent title={'Hirer Profile'} />
      <ProfileContainer>
        <ProfileInfo />
        <JobPostedList jobs={jobs || []} />
      </ProfileContainer>
    </Box>
  );
};

export default HirerProfile;
